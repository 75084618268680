
import ContactForm from '@/components/ContactForm.vue';
import { getApplicationName } from '@/router/Applications';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ContactForm },
  data() {
    const { appName } = this.$route.params;
    return {
      appName: getApplicationName(appName),
    };
  },
});
